export function waitForDialogClose(dialogId, thenCallback) {
    $(dialogId).on('hidden.bs.modal', function () {

        $(dialogId).off('hidden.bs.modal');

        thenCallback();
    });
}

export function closeDialogThen(dialogId, thenCallback) {

    waitForDialogClose(dialogId, thenCallback);

    $(dialogId).modal('hide');
}