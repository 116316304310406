const { FancyMsg } = require('./FancyMsg');

global.jQuery = require('jquery');
global.$ = jQuery;
global.jQueryValidation = require('jquery-validation');
global.jQueryUnobtrusiveValidation = require('jquery-validation-unobtrusive');
global.Tether = require('tether');

require('bootstrap');

global.bootgrid = require('jquery.bootgrid');

require('select2');

require('jquery-ajax-unobtrusive');

global.expressiveAnnotations = require('expressive-annotations-validate');

$(document).ready(function () {

    window.fancyMsg = new FancyMsg();
    window.loader = new Loader();

    const select2Class = '.apply-select2';
    const singleSelect = select2Class + ':not([multiple])';
    $(singleSelect).select2();

    const multipleSelect = select2Class + "[multiple]";
    $(multipleSelect).select2({
        placeholder: 'Select some options'
    });

    $(multipleSelect).on("select2:unselect", function (unselectEvent) {
        if (!unselectEvent.params.originalEvent) {
            return;
        }
        unselectEvent.params.originalEvent.stopPropagation();
    });
});

const Loader = function () {};

Loader.prototype.hide = function () {
    $('.loading').hide();
}

Loader.prototype.show = function () {
    $('.loading').show();
}